
// *** Utils ***
import "./js/utils/utils.js";
import "./js/editor-front.js";
import "./js/app.js";
import "./js/utils/css.js";
import "./js/utils/loader.js";

// *** Tools ***
import "./swiper/js/swiper.js";
import "./js/tools/scrolleasing.js";
import "./js/tools/typinganimation.js";
import "./js/tools/vc-animation.js";
import "./js/tools/dynamic-components.js";
import "./js/tools/aos.js";
import "./js/tools/dotlottie-player.js";

import "./js/tools/nav.js";
// import "./allsenses/js/fullpagescroll.js"

window.getComputedStyleByGlobalCompositeOperatorWithExclude=function(key,value){
	return getComputedStyle(document.body)[key].split(value).join("");
}