import {$} from "../utils/utils.js";

$(window).on("load",function(){

	$(".allsenses-gallery").each(function(){
		$(this).find("img").each(function(){
			galleryImages.addImage(new GalleryImage(this));
		});

		$(this).find("[data-horizontal]").each(function(){
			galleryImages.addImage(new GalleryImage(this));
		});

		$(this).find("[data-vertical]").each(function(){
			galleryImages.addImage(new GalleryImage(this));
		});
	});

})

class Gallery {

	constructor() {
		this.images = [];
		this.currentImage = null;
		this.currentImageIndex = null;

		this.createGallery();
		this.registerEvents();
	}

	createGallery() {
		this.gallery = document.createElement("div");
		this.gallery.classList.add("as-gallery");

		// Image container
		this.imageContainer = document.createElement("div");
		this.imageContainer.classList.add("as-gallery-image-container");
		this.imageContainer.addEventListener("click", (e) => {
			$(this.imageContainer).toggleClass("as-gallery-zoomed");
		});

		// SVG Spinner
		this.imageSpinner = document.createElement("span");
		this.imageSpinner.classList.add("as-gallery-image-spinner");

		// Image
		this.image = document.createElement("img");
		this.image.classList.add("as-gallery-image");
		this.image.addEventListener("mouseover", (e) => {
			$(this.gallery).addClass("as-gallery-hover");
		});
		this.image.addEventListener("mouseout", (e) => {
			$(this.gallery).removeClass("as-gallery-hover");
		});
		this.image.addEventListener("load", (e) => {
			$(this.gallery).removeClass("as-gallery-loading");
		});


		// Image description
		this.imageDescription = document.createElement("div");
		this.imageDescription.classList.add("as-gallery-image-description");

		// Image counter
		this.imageCounter = document.createElement("div");
		this.imageCounter.classList.add("as-gallery-image-counter");

		// Buttons group
		this.buttons = document.createElement("div");
		this.buttons.classList.add("as-gallery-buttons");

		// Next button
		this.nextButton = document.createElement("span");
		this.nextButton.classList.add("as-gallery-next");
		this.nextButton.innerHTML = "<i class='fas fa-chevron-right'></i>";
		this.nextButton.innerHTML = `<img class="icon icon-type-fontawesome-regular" data-src="/images/icons/fontawesome-regular/chevron-right.svg" width="24" height="24">`
		this.nextButton.addEventListener("click", () => this.next());

		// Previous button
		this.previousButton = document.createElement("span");
		this.previousButton.classList.add("as-gallery-previous");
		this.previousButton.innerHTML = "<i class='fas fa-chevron-left'></i>";
		this.previousButton.innerHTML = `<img class="icon icon-type-fontawesome-regular" data-src="/images/icons/fontawesome-regular/chevron-left.svg" width="24" height="24">`
		this.previousButton.addEventListener("click", () => this.previous());

		// Close button
		this.closeButton = document.createElement("span");
		this.closeButton.classList.add("as-gallery-close");
		this.closeButton.innerHTML = "<i class='fas fa-times'></i>";
		this.closeButton.innerHTML = `<img class="icon icon-type-fontawesome-regular" data-src="/images/icons/fontawesome-regular/xmark.svg" width="24" height="24">`
		this.closeButton.addEventListener("click", () => this.close());

		// Zoom button
		this.zoomButton = document.createElement("span");
		this.zoomButton.classList.add("as-gallery-zoom");
		this.zoomButton.innerHTML = "<i class='fas fa-search-plus'></i>";
		this.zoomButton.innerHTML = `<img class="icon icon-type-fontawesome-regular" data-src="/images/icons/fontawesome-regular/magnifying-glass-plus.svg" width="24" height="24">`
		this.zoomButton.addEventListener("click", (e) => {
			$(this.imageContainer).toggleClass("as-gallery-zoomed");
		});

		// Download button
		this.downloadButton = document.createElement("span");
		this.downloadButton.classList.add("as-gallery-download");
		this.downloadButton.innerHTML = "<i class='fas fa-download'></i>";
		this.downloadButton.innerHTML = `<img class="icon icon-type-fontawesome-regular" data-src="/images/icons/fontawesome-regular/download.svg" width="24" height="24">`
		this.downloadButton.addEventListener("click", () => this.donwnload());

		// Append elements
		this.imageContainer.appendChild(this.image);
		this.imageContainer.appendChild(this.imageSpinner);
		this.gallery.appendChild(this.previousButton);
		this.gallery.appendChild(this.nextButton);
		this.buttons.appendChild(this.zoomButton);
		this.buttons.appendChild(this.downloadButton);
		this.buttons.appendChild(this.closeButton);
		this.gallery.appendChild(this.buttons);
		this.gallery.appendChild(this.imageContainer);
		this.gallery.appendChild(this.imageDescription);
		this.gallery.appendChild(this.imageCounter);
		document.body.appendChild(this.gallery);
	}

	registerEvents() {
		$(document).on("keydown", (e) => {
			if (this.gallery.classList.contains("as-gallery-active")) {
				switch (e.keyCode) {
					case 27:
						this.close();
						break;
					case 37:
						this.previous();
						break;
					case 39:
						this.next();
						break;
				}
			}
		});

		$(this.gallery).on("click", (e) => {
			if (e.target === this.gallery) {
				this.close();
			}
		});
	}

	open() {
		this.gallery.classList.add("as-gallery-active");
		loadAllMain();
	}

	close() {
		this.gallery.classList.remove("as-gallery-active");
	}

	next() {
		let nextImage = this.getNextImage();
		if (nextImage) {
			$(this.imageContainer).removeClass("as-gallery-zoomed");
			this.setCurrentImage(nextImage);
		}
	}

	previous() {
		let previousImage = this.getPreviousImage();
		if (previousImage) {
			$(this.imageContainer).removeClass("as-gallery-zoomed");
			this.setCurrentImage(previousImage);
		}
	}

	donwnload() {
		if (this.currentImage) {
			let link = document.createElement("a");
			link.setAttribute("href", this.currentImage.getHQSource());
			link.setAttribute("download", this.currentImage.element.alt || "image");
			link.click();
		}
	}

	addImage(image) {
		for (let i = 0; i < this.images.length; i++) {
			if (this.images[i].element === image.element) {
				return;
			}
		}

		this.images.push(image);
	}

	getImage(element) {
		return this.images.find(image => image.element === element);
	}

	getImageByIndex(index) {
		return this.images[index];
	}

	getNextImage() {
		let currentIndex = this.currentImageIndex;
		let nextIndex = currentIndex + 1;
		if (nextIndex >= this.images.length) {
			nextIndex = 0;
		}
		console.log(nextIndex);
		return this.getImageByIndex(nextIndex);
	}

	getPreviousImage() {
		let currentIndex = this.currentImageIndex;
		let previousIndex = currentIndex - 1;
		if (previousIndex < 0) {
			previousIndex = this.images.length - 1;
		}
		console.log(previousIndex);
		return this.getImageByIndex(previousIndex);
	}

	setCurrentImage(image) {
		this.currentImage = image;
		this.currentImageIndex = parseInt($(image.element).data("gallery-index"))

		this.imageCounter.innerHTML = `${this.currentImageIndex + 1} / ${this.images.length}`;

		delete this.image.src;
		this.image.src = image.getHQSource();
		$(this.gallery).addClass("as-gallery-loading");

		this.open();
	}


}

class GalleryImage {

	constructor(element) {
		this.element = element;
		this.register();
	}

	getSource() {
		let src = "";
		if (this.element.tagName === "IMG") {
			src = this.element.src || this.element.getAttribute("data-src");
			src = src.replace(/^(?:\/\/|[^\/]+)*\//, "");
			if (!src.startsWith("/")) src = "/" + src;
		} else {
			src = this.element.dataset.horizontal || this.element.dataset.vertical;
		}

		return src;
	}

	getHQSource() {
		let src = this.getSource();
		if (src.startsWith("/th/")) {
			let split = src.split("/");
			split[2] = "org";
			src = split.join("/");
		}
		return src;
	}

	register() {
		if (galleryImages.images.find(image => image.element === this.element)) return;
		this.element.setAttribute("data-gallery-index", galleryImages.images.length);

		let that = this;
		$(this.element).on("click", function () {
			galleryImages.setCurrentImage(that);
		});
	}


}

window.galleryImages = new Gallery();